<template>
  <div>
    <Navbar page="Agentes de tratamento" link="/cadastros" nameLink="Cadastros" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <h1 class="text-3xl mb-5">Agentes de tratamento</h1>

      <a :href="`${url_api}/v1/${route}/exportar/${$store.state.empresa._id}?token=${$store.state.token}`" target= "_blank" rel="noreferrer" class="bg-blue-400 hover:bg-blue-500 mb-2 focus:bg-blue-500 focus:shadow-sm focus:ring-4 text-white flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-40 font-semibold justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
        </svg>
        Download base 
      </a>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-10 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Filtrando Nome" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Nome
                    </th>
                    <th v-if="!$store.state.empresa" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Empresa
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <td v-if="!$store.state.empresa" class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.empresa ? item.empresa.nome : ''}}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'agentes',
      list: [],
      page: 1,
      perPage: 10,
      busca: '',
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse agente?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, busca: this.busca };
      this.$router.push({ path: `/cadastros/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.busca = this.busca;

      this.$router.push({ path: `/cadastros/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>